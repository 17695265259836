<template>
  <div class="ec-header flex flex-col-reverse md:flex-row gap-2 mb-3">
    <div class="search-wraper">
      <div class="flex gap-2 items-center">
        <form class="flex-1 relative" @submit.prevent="handleSearch">
          <el-input
            @keyup.enter="handleSearch"
            @input="debounceSearch"
            @clear="clearData"
            placeholder="Tìm kiếm tên sản phẩm ..."
            v-model="searchInput"
            class="fs-18"
          >
            <i
              slot="suffix"
              @click="handleSearch"
              class="el-input__icon el-icon-search cursor-pointer txt-grey-900 fs-20"
            ></i>
          </el-input>
          <span
            class="absolute z-10 top-1/2 right-12 -mt-3 input-group-text c-pointer btn-close"
            @click="clearData"
            v-if="searchInput"
          ></span>
        </form>

        <div>
          <svg
            v-if="appUtils.checkIsMobileAndTablet()"
            class="ml-1"
            @click="isShowDrawer = true"
            width="34"
            height="34"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16ZM5 9.5H27C27.3978 9.5 27.7794 9.34196 28.0607 9.06066C28.342 8.77936 28.5 8.39782 28.5 8C28.5 7.60218 28.342 7.22064 28.0607 6.93934C27.7794 6.65804 27.3978 6.5 27 6.5H5C4.60218 6.5 4.22064 6.65804 3.93934 6.93934C3.65804 7.22064 3.5 7.60218 3.5 8C3.5 8.39782 3.65804 8.77936 3.93934 9.06066C4.22064 9.34196 4.60218 9.5 5 9.5ZM27 22.5H5C4.60218 22.5 4.22064 22.658 3.93934 22.9393C3.65804 23.2206 3.5 23.6022 3.5 24C3.5 24.3978 3.65804 24.7794 3.93934 25.0607C4.22064 25.342 4.60218 25.5 5 25.5H27C27.3978 25.5 27.7794 25.342 28.0607 25.0607C28.342 24.7794 28.5 24.3978 28.5 24C28.5 23.6022 28.342 23.2206 28.0607 22.9393C27.7794 22.658 27.3978 22.5 27 22.5Z"
              fill="#777E91"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="cart-wraper">
      <ShoppingCartCpn />
    </div>

    <el-drawer
      size="70%"
      title=""
      :visible.sync="isShowDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <el-radio-group
        class="cs-radio flex flex-col gap-2 px-2"
        v-model="category_type"
        @change="handleChangeCategories"
      >
        <el-radio label="All">{{ $t("Tất cả") }}</el-radio>
        <el-radio
          v-for="(item, index) in categories"
          :key="index"
          :label="item.id"
          >{{ item.name }}</el-radio
        >
      </el-radio-group>
    </el-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    border: 2px solid rgba(213, 210, 249, 1);
    height: 40px;
    border-radius: 67px;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 45px;
  }
  .el-input__suffix {
    right: 18px;
  }
}
</style>

<script>
import appUtils from '@/utils/appUtils'
import ShoppingCartCpn from './ShoppingCartCpn.vue'

export default {
  name: 'EcomHeader',
  props: {
    categories: Array
  },
  data () {
    return {
      searchInput: '',
      debounce: null,
      typing: false,
      isShowDrawer: false,
      direction: 'rtl',
      category_type: 'All',
      appUtils
    }
  },
  components: { ShoppingCartCpn },
  async created () {
    const query = this.$route.query

    if (query?.search) {
      this.searchInput = query?.search
    }
  },
  mounted () {
    const query = this.$route.query

    if (query?.type) {
      const id =
        this.categories?.find(
          (item) => appUtils.slugifyStr(item?.name) === query.type
        )?.id || 'All'
      this.category_type = id
    }
  },
  watch: {
    $route: {
      handler (data) {
        if (data.query?.search) {
          this.searchInput = data.query?.search
        }
      },
      deep: true
    },
    categories: {
      deep: true,
      handler () {
        const query = this.$route.query

        if (query?.type) {
          const id =
            this.categories?.find(
              (item) => appUtils.slugifyStr(item?.name) === query.type
            )?.id || 'All'

          this.category_type = id
        }
      }
    }
  },
  methods: {
    handleSearch () {
      this.$router
        .push({
          name: 'Ecommerce',
          query: {
            ...this.$route.query,
            search: this.searchInput
          }
        })
        .catch((_) => {})
      this.$emit('setSearchInput', this.searchInput)
    },
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        if (!this.searchInput?.length) {
          this.clearData()
        }
      }, 600)
    },
    clearData () {
      this.searchInput = ''
      this.$router
        .push({
          query: {
            ...this.$route.query,
            search: ''
          }
        })
        .catch((_) => {})
      this.$emit('setSearchInput', this.searchInput)
    },
    handleClose () {
      this.isShowDrawer = false
    },
    handleChangeCategories () {
      const index = this.categories.findIndex(
        (item) => item.id === this.category_type
      )
      this.$emit('onChangeCategories', this.category_type, index)
      this.handleClose()
    }
  },
  beforeDestroy () {
    // this.clearData()
  }
}
</script>

<style lang="scss" scoped >
::v-deep {
  .cs-radio {
    display: flex !important;

    .el-radio {
      height: 40px !important;
    }
  }
}
</style>
