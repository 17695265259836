<template>
  <div class="product-item cursor-pointer shadow" @click="goToItem(product)">
    <div class="flex justify-content-center overflow-hidden rounded-t-2xl">
      <img
        class="product-item-img w-full mb-0"
        :src="product.primary_image"
        @error="(e) => appUtils.onHandleErrorLoadImage(e)"
      />
    </div>
    <div class="p-3">
      <div class="product-item-name mb-0">
        <p class="fs-18 fw-500 txt-gey-900 mb-0 max-line-2">
          {{ product.name }}
        </p>
      </div>
      <div
        v-if="product.supplier && product.supplier.name"
        class="product-item-name flex gap-1 capitalize my-1"
      >
        <span class="txt-gey-900 mb-0 max-line-2">{{
          product.supplier.name.toLowerCase()
        }}</span>
      </div>

      <div v-if="product.old_price" class="product-item-price mb-0">
        <p
          class="fs-18 txt-grey-500 mb-0"
          :class="product.old_price ? 'text-decoration-line-through' : ''"
        >
          {{
            product.old_price
              ? `${appUtils.numberFormat(product.old_price)} VNĐ`
              : `&nbsp;`
          }}
        </p>
      </div>
      <div class="product-item-sale-price">
        <div class="flex fs-18 fw-700 mb-0 items-center whitespace-nowrap">
          <span class="txt-pri">
            {{
              product.default_price
                ? `${appUtils.numberFormat(product.default_price)} VNĐ`
                : "Liên hệ"
            }}
          </span>
          <span
            v-if="product.default_price && product.default_unit_name"
            class="fs-16 fw-500 txt-gey-900"
            >/ {{ product.default_unit_name }}</span
          >
        </div>
      </div>
      <div @click.stop>
        <button
          type="button"
          class="btn bg-pri text-white text-center w-full py-1 rounded-lg h-10 items-center"
          :disabled="!product.default_price"
          @click="handleAddToCart(product)"
        >
          <span class="py-1 flex items-center justify-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 14 14"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.946 13.5a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m-6.5 0a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m5.158-7h-3m1.5-1.5v3M.5.5H3L3.263 3m0 0l.643 6.105A1 1 0 0 0 4.9 10h6.32a1 1 0 0 0 .97-.757l1.25-5A1 1 0 0 0 12.47 3z"
              />
            </svg>
            <span class="fs-16 fw-500 text-white mb-0 align-middle">{{
              $t("Chọn")
            }}</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { PRODUCT_TYPE } from '../../utils/constant'

export default {
  name: 'ProductItem',
  props: {
    product: Object
  },
  data () {
    return {
      appUtils,
      PRODUCT_TYPE
    }
  },
  methods: {
    goToItem (product) {
      this.$router.push({ path: `/ecommerce/${product.slug}` })
    },
    handleAddToCart (product) {
      if (product.is_require_form === 1) {
        this.$emit('clickService', product)
      } else if (
        product.type === PRODUCT_TYPE.SERVICE &&
        (!product.is_require_form || product.is_require_form === 2)
      ) {
        const selectedVariant =
          product.product_variants?.find((elm) => elm.is_default === 2) ||
          product.product_variants?.find((elm) => elm.conversion_rate === 1) ||
          product.product_variants[0]

        const data = {
          ...product,
          selected_variant: selectedVariant
        }

        appUtils.addToCart(data, product.product_variants[0]?.id)

        window.$toast.open({
          message: this.$t('Thêm vào giỏ hàng thành công'),
          type: 'success'
        })

        // if (!this.$user) {
        //   this.$targetRoute = '/#/shopping-cart'
        //   this.$router.push({ name: 'Login' }).catch((_) => {})
        //   return false
        // }

        // this.$router
        //   .push({
        //     name: 'OrderConfirm'
        //   })
        //   .catch((_) => {})
      } else {
        const selectedVariant =
          product.product_variants?.find((elm) => elm.is_default === 2) ||
          product.product_variants?.find((elm) => elm.conversion_rate === 1) ||
          product.product_variants[0]

        const availableVariant = selectedVariant?.qty
          ? selectedVariant
          : product.product_variants?.find((elm) => elm?.qty)

        const data = {
          ...product,
          selected_variant: availableVariant
        }

        if (!availableVariant.qty) {
          window.$toast.open({
            message: this.$t('Sản phẩm đã hết hàng!'),
            type: 'warning'
          })
          return
        }

        appUtils.addToCart(data, data.selected_variant?.id)
        window.$toast.open({
          message: this.$t('Thêm vào giỏ hàng thành công'),
          type: 'success'
        })
      }
    }
  }
}
</script>

<style>
</style>
