<template>
  <div
    class="modal fade"
    id="modalConfirmPac"
    tabindex="-1"
    aria-labelledby="modalConfirmPac"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content radius-15 p-4">
        <div class="mb-3">
          <div class="text-right flex justify-between items-center">
            <div class="fs-20 fw-600">Dịch vụ yêu cầu tài liệu đính kèm</div>
            <svg
              @click="showModalConfirm(false)"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path
                d="M6 18.4998L17.9998 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.9998 18.4998L6 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="fw-500">Dịch vụ này yêu cầu bạn nộp tài liệu kèm theo sau đây. Bạn vẫn muốn tiếp tục?</div>
        <ul>
          <li v-for="form in requireForms" :key="form.id">{{ form.name }}
          </li>
        </ul>
        <div class="flex justify-end mt-5 space-x-2">
          <button
            class="btn btn-light w-32 p-2"
            @click="showModalConfirm(false)"
          >Hủy</button>
          <button
            @click="confirm"
            class="btn btn-pri w-32 p-2"
          >Đồng ý</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmRequirePaper',
  data () {
    return {
      payload: {},
      requireForms: []
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm', this.payload)
      this.showModalConfirm(false)
    },
    showModalConfirm (show, payload) {
      this.payload = payload
      this.requireForms = payload?.requireForms
      window.$('#modalConfirmPac').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

  <style lang="css" scoped>
</style>
